import Head from "next/head";
import Link from "next/link";

export default function NotFound() {
  return (
    <div>
      <Head>
        <title>Spotlight</title>
      </Head>
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-12 text-center">
            <span className="display-1">404</span>
            <div className="mb-4">
              The page you are looking for was not found.
            </div>
            <Link href="/">
              <a className="btn btn-primary">Back to Home</a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
